import Swiper from 'swiper/bundle';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';



export default {
  init() {
    // JavaScript to be fired on all pages
    $(document).foundation();
    window.addEventListener('scroll', reveal);
    function reveal(){
      if($('.ZoomInUp').length > 0){
      var reveals = document.querySelectorAll('.ZoomInUp');
      for (var i = 0; i < reveals.length; i++){
        var win_height = window.innerHeight;
        var reveal_top = reveals[i].getBoundingClientRect().top;
        var reveal_point = 150;
        if (reveal_top < win_height - reveal_point) {
          reveals[i].classList.addClass('active');
        } //else {
          //reveals[i].classList.remove('active');
        //}
      }
    }
  }
  /*Sticky Header*/
  $(window).scroll(function () {
      if ($(window).scrollTop() >= 100) {
          $('.sticky-header').addClass('is-sticky');
      } else {
          $('.sticky-header').removeClass('is-sticky');
      }
  });
       
  $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
  });  

  $('p').each(function() {
    var $this = $(this);
    if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
      $this.remove();
  });
  $('p span').each(function() {
    var $this = $(this);
    if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
      $this.remove();
  });
  lightGallery(document.getElementById('imageGallery'), {
      plugins: [lgVideo],
      //videojs: true,
      videojsOptions: {
          muted: true,
      },
  });
    // js for onscroll animation
    gsap.registerPlugin(ScrollTrigger);

    // const text_anim = gsap.utils.toArray('.fade-animation');
    // text_anim.forEach((box, i) => {
    //     const anim = gsap.fromTo(box.children, { y: 150, opacity: 0 ,autoAlpha:0}, { duration: 0.4, y: 0, opacity: 1 ,stagger: 1,autoAlpha:1});
    //     ScrollTrigger.create({
    //         trigger: box,
    //         ease: 'power2.in',
    //         animation: anim,
    //         once: false,
    //     });
    // });
    const textfade = gsap.utils.toArray('.fade-animation');
    gsap.registerPlugin(ScrollTrigger);
    textfade.forEach((leng, i) => {
      const anim = gsap.fromTo(leng, { autoAlpha:0,y:100 }, {y:0, autoAlpha:1,duration:1,delay:0.5,stagger:0.5 });
      ScrollTrigger.create({
          trigger: leng,
          ease: "Power1.easeIn",
          animation: anim,
          toggleActions: 'play none none none',
      });
    });
    const zoomImage = gsap.utils.toArray('.zoomIn');
    zoomImage.forEach((box, i) => {
        const zoomInAnim = gsap.to(box, 0.5, {duration: 0.3, scale: 1.001,autoAlpha:1});
        ScrollTrigger.create({
            trigger: box,
            ease: 'Power1.easeIn',
            animation: zoomInAnim,
            once: false,
        });
    });

    $('.toggle-btn').click(function () {
        $('.drop-down-menu').toggleClass('open');
        $('html').toggleClass('overflow-hidden');
    });

    const mk = gsap.timeline({
        paused: true,
        // onStart: menuOpen,
        // onReverseComplete: menuClose,
    });
    mk.to('.drop-down-menu', {
        clipPath: 'circle(140% at 96.5% 4%)',
        transition: 'all 0.1s cubic-bezier(0.23, 0.93, 0.23, 0.93)',
    })
    mk.to('.left-side-menu .menu-item a', {
        translateY: '0',
        duration: 0.05,
    }) 
    mk.to('.center-menu .menu-item a', {
        translateY: '0',
        duration: 0.05,
    })
    mk.to('.right-side-menu .contact-info .section-title h2', {
        translateY: '0',
        duration: 0.05,
    })
    mk.to('.right-side-menu .contact-info .address-link li a', {
        translateY: '0',
        duration: 0.05,
    })
    mk.to('.right-side-menu .contact-info .social-link li a', {
        translateY: '0',
        duration: 0.05,
    })
    mk.reverse();
    $('#responsive-search .search-icon').click(function () {
        $(this).parent().parent('#responsive-search').toggleClass('open');
    }); 
    $('.toggle').click(function () {
        mk.reversed(!mk.reversed());
        $(".drop-down-menu").toggleClass('open');
        $("html").toggleClass('overflow-hidden');
    }); 
    $('.drop-down-menu .left-side-menu .menu-item a').after('<span class="border-design"></span>');
    $('.drop-down-menu .center-menu .menu-item a').after('<span class="border-design"></span>');
    $('.search-icon').click(function () {
        $(".search-form").toggleClass('open');
    });
    $('[open-modal]').on('click', function(){
        var id = $(this).attr('open-modal');
        $('.modal#' + id).addClass('active');
        $('html').addClass('overflow-hidden');
    });
      
    $('[close-modal]').on('click', function(){
        $(this).parents('.modal').removeClass('active');
        $('html').removeClass('overflow-hidden');
    });

    $(".project-slider .imageSwiper .swiper-slide-active").on('click',function(){
      $('.galleryModal').removeClass('hide');
      $('html').addClass('overflow-hidden');
    });
    $(".close-modal").click(function(){
      $('.galleryModal').addClass('hide');
      $('html').removeClass('overflow-hidden');
    });
    $('.gform_button').wrap('<div class="mk-btn green-btn"></div>');
    var thumbSwiper = new Swiper('.thumbSwiper', {
      direction: 'vertical',
      effect: 'fade',
      autoplay: {
          delay: 5500,
          disableOnInteraction: false,
      },
      speed:1400,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
          0: {
              direction: 'horizontal',
          },
          1024: {
              direction: 'vertical',
          },
      }
    });
  var heroBanner = new Swiper('.heroBanner', {
      effect: 'fade',
      thumbs: {
          heroBanner: thumbSwiper
      }
  });
  heroBanner.controller.control = thumbSwiper;
  thumbSwiper.controller.control = heroBanner;

  var testimonialSwiper = new Swiper('.testimonialSwiper', {
      spaceBetween:30,
      pagination: {
            el: ".testimonial-fraction",
            type: "fraction",
            formatFractionCurrent: function (number) {
               return ('0' + number).slice(-2);
            },
            formatFractionTotal: function (number) {
                return ('0' + number).slice(-2);
            },
        },
      navigation: {
        nextEl: '.next-testimonial',
        prevEl: '.prev-testimonial',
      },
  });
  var latestSwiper = new Swiper(".latestSwiper", {
    slidesPerView: 3,
    spaceBetween:30,
    navigation: {
      nextEl: ".next-blog",
      prevEl: ".prev-blog",
    },
    pagination: {
        el: ".blog-fraction",
        type: "fraction",
    },
    breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1300: {
          slidesPerView: 3,
        },
      },
  });
   var imageSwiper = new Swiper('.imageSwiper', {
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 30,
        centerInsufficientSlides:false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on : {
            slideChange: function () {
                $(document).on('click', '.project-slider .imageSwiper .swiper-slide-active', function () {
                  $('.galleryModal').removeClass('hide');
                  $('html').addClass('overflow-hidden');
                });
            }
        },
        
   });
    
    var galleryThumbSwiper = new Swiper(".galleryThumbSwiper", {
        spaceBetween: 19,
        slidesPerView: 4,
        freeMode: true,
      watchSlidesProgress: true,
        breakpoints: {
          0: {
            slidesPerView: 10,
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 19,
            slidesPerView: 4,
          },
        }
      });
      var gallerySwiper = new Swiper(".gallerySwiper", {
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: galleryThumbSwiper,
        },
      });
      var careerSwiper = new Swiper(".careerSwiper", {
        slidesPerView: 3,
        spaceBetween: 58,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1190: {
            slidesPerView: 3,
          },
        },
      });
      var partnerSwiper = new Swiper(".partnerSwiper", {
        slidesPerView: 3,
        spaceBetween: 20,
        navigation: {
          nextEl: ".next-associate",
          prevEl: ".prev-associate",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          460: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 2,
          },
          1199: {
            slidesPerView: 3,
          },
        },
    });
    var blogSlider = new Swiper(".blogSlider", {
      spaceBetween: 55,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesProgress: true,
      navigation: {
          nextEl: ".next-img",
          prevEl: ".prev-img",
      },
      breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          560: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1023: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1299: {
            slidesPerView: 5,
            spaceBetween: 55,
          },
      },
    });
    $('#goToTop').on("click",function(){  
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });
    //playpuase video
    var videoElement = document.querySelector('video');
    var playPauseButton = document.querySelector('.video-control');
    if (playPauseButton) {
      playPauseButton.addEventListener('click', function () {
          playPauseButton.classList.toggle('playing');
          if (playPauseButton.classList.contains('playing')) {
          videoElement.play();
          }
          else {
          videoElement.pause();
          }
      });
    }
    if (videoElement) {
    videoElement.addEventListener('ended', function () {
        playPauseButton.classList.remove('playing');
    });
    } 

    //certification Load button
    $(".certification-grid .tabs-md").slice(0,12).show();
      if($(".certification-grid .tabs-md").length <= 12) {
          $("#certification-loadmore").hide();
    }
    $("#certification-loadmore").click(function(e){
        e.preventDefault();
        $(".certification-grid .tabs-md:hidden").fadeIn("slow");
        $("#certification-loadmore").hide();      
    });
    
    //partner Load button
    $(".partner-grid .tabs-md").slice(0,8).show();
      if($(".partner-grid .tabs-md").length <= 8) {
          $("#partner-loadbtn").hide();
      }
    $("#partner-loadbtn").click(function(e){
        e.preventDefault();
      $(".partner-grid .tabs-md:hidden").fadeIn("slow");
      $("#partner-loadbtn").hide();        
    });
    

    //search Load button
    $(".search-grid .search-tabs-md").slice(0,6).show();
      if($(".search-grid .search-tabs-md").length <= 6) {
          $("#search-load").hide();
      }
    $("#search-load").click(function(e){
        e.preventDefault();
        $(".search-grid .search-tabs-md:hidden").fadeIn("slow");
        $("#search-load").hide();      
    });
    $(".ui-datepicker-trigger").attr("src",null); 
     //Blog Load button
    $(".blog-grid .blog-tabs-md").slice(0,6).show();
      if($(".blog-grid .blog-tabs-md").length <= 6) {
          $("#blog-loadmore").hide();
      }
    $("#blog-loadmore").click(function(e){
        e.preventDefault();
        $(".blog-grid .blog-tabs-md:hidden").fadeIn("slow");
        $("#blog-loadmore").hide();      
    });

    //Career Load button
    $(".career-gid .career-tabs-md").slice(0,6).show();
      if($(".career-gid .career-tabs-md").length <= 6) {
          $("#career-loadmore").hide();
      }
    $("#career-loadmore").click(function(e){
        e.preventDefault();
        $(".career-gid .career-tabs-md:hidden").fadeIn("slow");
        $("#career-loadmore").hide();      
    });

    // contact us form
    $(document).on('gform_post_render', function(){        
        if($('#gform_1').length){
            if ($('.gfield--width-half').hasClass('ctm-input-1')){  
                $(".ctm-input-1").append($(".ctm-in-1"));
            }
        }
    });


    // project page search

    jQuery('#my-ajax-filter-search input').keyup(function()
    {

        if( jQuery(this).val().length !== 0 ) {
          jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: 'post',
            data: { action: 'data_fetch', keyword: jQuery('#keyword').val() },
            success: function(data) {
                if(data){
                  jQuery('#proj_listing').html( data );
                  jQuery('#proj_listing_page').hide();
                } else {
                  jQuery('#proj_listing').html( '<h4>Project Not Found</h4>' );
                  jQuery('#proj_listing_page').hide();
                }
            },
        });
        }
        else {
           jQuery('#proj_listing_page').show();
          jQuery('#proj_listing').empty();
          // jQuery('.news-pagination').show();
        }
    });

    jQuery( '#my-ajax-filter-search' ).bind( 'keypress keydown keyup', function(e) {
      if (e.keyCode == 13) {
          e.preventDefault();
      }
    });

      // project page filter

      if($('#filterby').length){
        $('select#filterby').change(function(){
          var filters = $.map($('select#filterby').toArray(), function(e){
            return $(e).val();
        }).join('.');
      
          $('div.filter_listing .proj-wrapper').addClass('hidebox');
          $('div.filter_listing').find('.proj-wrapper.' + filters).removeClass('hidebox');
          
          $('.project-list .grid-container').each(function () {
            console.log(filters);
            if ($(this).find('.proj-wrapper.' + filters).length === 0) {
              $(this).find('.not-found-message').removeClass('hidden');
              $(this).find('.center-btn').addClass('hide');
            }
            else {
              $(this).find('.not-found-message').addClass('hidden');
              $(this).find('.center-btn').removeClass('hide');
            }
          });
        });
  }
    
    // Project Load More
    let projectPage = 1;
    if (jQuery('#proj_listing_page').length) {
      loadmore_projects();
    }
    $('body').on('click', '#proj_listing_page a.projectLoadMore', function () {
      loadmore_projects();
    });
    function loadmore_projects() {
      var ppp = $('#ppp_ev').val();
      var cppp = $('#cppp_ev').val();
      if (cppp > 0) {
      ppp = cppp;
      }
      $('#proj_listing_page a.projectLoadMore').text('Loading...');
      var str =
      'pageNumber=' +
      projectPage +
      '&ppp=' +
      ppp +
      '&cppp=' +
      cppp +
      '&action=load_project_by_ajax_callback';
      $.ajax({
      type: 'POST',
      dataType: 'html',
      url: ajax_object.ajaxurl,
      data: str,
      success: function (data) {
        var $data = JSON.parse(data);
        if ($data['result'] != '') {
        $('#proj_listing_page .proj_listing_page_inner').append($data['result']);
        projectPage++;
        } else {
        $('#proj_listing_page .proj_listing_page_inner').html('<h4>No Projects found</h4>');
        }
        if ($data['load_more'] == '1' && ppp != '-1') {
        $('#proj_listing_page a.projectLoadMore').text('View All.');
        $('#proj_listing_page a.projectLoadMore').show();
        } else {
        $('#proj_listing_page a.projectLoadMore').text('View All.');
        $('#proj_listing_page a.projectLoadMore').hide();
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $('#proj_listing_page .proj_listing_page_inner').html('<h4>Something went wrong</h4>');
      },
      });
      return false;
    }

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
